/* Copyright (C) Okahu Inc 2023-2024. All rights reserved. */

'use client';

import React from 'react';

import { Icons } from '../icons';
import { Input } from '../ui/input';

interface SearchInputProps {
  onChange: (value: string) => void;
  value: string;
  onClear: () => void;
  placeholder?: string;
  className?: string;
  icon?: React.ReactNode;
  inputClassName?: string;
  clearButtonClassName?: string;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  value,
  onClear,
  placeholder = 'Search',
  className = '',
  icon = Icons.Search(),
  inputClassName = '',
  clearButtonClassName = '',
  ...props
}) => {
  return (
    <div
      className={`flex h-8 w-[178px] items-center gap-1.5 rounded-sm border border-form-control-border-rest p-2 pl-3 ${className}`}
    >
      <span>{icon}</span>
      <Input
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className={`border-none p-0 text-form-input-text text-form-input-rest focus-visible:ring-0 focus-visible:ring-offset-0 ${inputClassName}`}
        value={value}
        {...props}
      />
      {value && value.toString().length > 0 && (
        <span
          onClick={onClear}
          className={`cursor-pointer ${clearButtonClassName}`}
        >
          {Icons.Close('size-4 text-form-control-border-rest')}
        </span>
      )}
    </div>
  );
};
